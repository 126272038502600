import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import "aos/dist/aos.css";
import Aboveinfo from "../../components/aboveinfo.js";
import ConnectWallet from "../../components/ConnectWallet";

import {
  validation,
  approveToken,
  JoinPlan,
  getReferalAddr,
  checkUser
} from "../joinNow/joinnowdetail.js";
import { toastAlert } from "../../helper/toastAlert";
import { isAddress } from "../../helper/custom";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Joinnow(props) {

  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();

  const [ref, setref] = useState("");
  const [error, seterror] = useState("");
  const [isDisable, setisDisable] = useState(false);

  useEffect(() => {
    loadRef();
    //eslint-disable-next-line
  }, [walletConnection]);

  function loadRef() {
    seterror("");
    var resp = getReferalAddr();
    if (isAddress(resp)) {
      setref(resp);
    }
  }

  async function submitReg() {

    setisDisable(true);
    seterror("");
    try {

      var { errorvalidate, isAllowance, joinAmt } = await validation(ref);
      if (errorvalidate && errorvalidate !== "") {
        seterror(errorvalidate);
        setisDisable(false)
        return;
      }

      if (isAllowance) {
        var { isAllowed, approvalAmt, error } = await approveToken();
        if (error && error !== "") {
          toastAlert("error", error);
          setisDisable(false)
          return;
        } else if (isAllowed && approvalAmt >= joinAmt) {
          toastAlert("success", "Successfully approved");
          isAllowance = false;
        } else {
          if (approvalAmt < joinAmt) {
            toastAlert("error", "Insufficient approved USDT");
          } else {
            toastAlert("error", "Failed to approved");
          }
          setisDisable(false)
          return;
        }
        console.log(approvalAmt, joinAmt, 'joinAmtjoinAmt')
      }

      if (!isAllowance) {
        var { errMsg, result } = await JoinPlan(ref);
        console.log(result, 'resultresultresult')
        if (errMsg && errMsg !== "") {
          seterror(errMsg);
          toastAlert("error", errMsg);
        } else {
          toastAlert("success", "Congratulations successfully registered");
          navigate("/dashboard")
        }
      }

      setisDisable(false)
    } catch (err) {
      console.log(err, 'errerrerr')
      toastAlert("error", "Please try again later");
      setisDisable(false)
    }
  }

  async function LoginNow() {
    var { isExist } = await checkUser();
    if (isExist) {
      navigate("/dashboard")
    } else {
      toastAlert("error", "Please Register and continue");
    }

  }


  return (
    <div>
      <ScrollToTopOnMount />
      <Aboveinfo />
      <div className="mob_bg" >
        <Navbar />
      </div>
      <div className="inner_wrapper">
        <div className="container">
          <div className="join_now_panel">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h2 className="h2tag mb-4 text-center">Join Now</h2>
                <div className="box">
                  <div className="row">
                    <div className="col-lg-6 m-auto">
                      <label for="exampleFormControlInput1" class="form-label h5tag">Referral ID</label>
                      <input
                        type="text"
                        class="form-control primary_inp"
                        id="exampleFormControlInput11"
                        onChange={e => setref(e.target.value)}
                        value={ref}
                      />
                      {error && error !== "" && <p style={{ color: 'red' }}>{error}</p>}
                      {walletConnection && walletConnection.address !== "" && walletConnection.connect === "yes" ?
                        <div className="d-flex" >
                          <button
                            type="button"
                            disabled={isDisable}
                            className="primary_btn mt-4 m-0"
                            onClick={() => submitReg()}
                          >
                            Submit
                            {isDisable && <i className="fas fa-spinner fa-spin"></i>}
                          </button>
                          <a href="#/" className="primary_btn mt-4 ms-3 m-0" onClick={() => LoginNow()}>Login</a>
                        </div> :
                        <button
                          type="button"
                          className="primary_btn mt-4"
                          data-bs-toggle="modal"
                          data-bs-target="#connect_wallet_modal"
                        >
                          Connect wallet
                        </button>

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnectWallet />
      <Footer />
    </div>
  );
}
