var key = {}
if (process.env.NODE_ENV === "production") {
  key = {
    projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
    networkId: 56,
    contract: "0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4",
    busdAddress: "0x55d398326f99059fF775485246999027B3197955",
    frontUrl: "https://bsmartcash.io",
    serverUrl: "https://controlsapi.bsmartcash.io/",
    txLink: "https://bscscan.com",
    rpcUrl: "https://bsc.publicnode.com",
    bscanApi: 'https://api.bscscan.com/api?module=account&action=txlist&address=0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4&page=1&offset=10&sort=desc&apikey=C7Q16AU6BMYD1DTPHHKH2QQ4XFDIKAGGV5',
    baseUrl: "https://productionapi.bsmartcash.io/",
  };
} else {
  key = {
    projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
    networkId: 56,
    contract: "0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4",
    busdAddress: "0x55d398326f99059fF775485246999027B3197955",
    frontUrl: "https://bsmartcash.io",
    serverUrl: "https://controlsapi.bsmartcash.io/",
    txLink: "https://bscscan.com",
    rpcUrl: "https://bsc.publicnode.com",
    bscanApi: 'https://api.bscscan.com/api?module=account&action=txlist&address=0x4b88a7408274BA32ADda4D3E0F24231b5b82bBD4&page=1&offset=10&sort=desc&apikey=C7Q16AU6BMYD1DTPHHKH2QQ4XFDIKAGGV5',
    baseUrl: "https://productionapi.bsmartcash.io/",
  };
}
export default key;